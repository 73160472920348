// Helpers for registration JavaScript Packs

// Add ClassNames to labels for required inputs (for scss to be selected)
export function addAsterisksToReqFields(reqFields) {
  reqFields.each(function () {
    const label = $(this).siblings("label").first()
    $(this).attr("required", "required")
    $(label).addClass("required")
  })
}
