require("./show")
import { screenResponsiveness } from "../../responsive"

$(document).on("turbolinks:load", function () {
  // guard for not being on /clients#index
  if ($(".customers").length === 0) return
  console.log("customers/index.js got past the guard!")

  function setClientsContainerHeight() {
    setTimeout(() => {
      const contentHeight = $(".customers").outerHeight()
      const topControlsHeight = $(".top-controls").outerHeight()
      $("#clients-results")
        .parent()
        .css("height", contentHeight - topControlsHeight)
    }, 200)
  }

  const $addNewClient = $("#add-new-client")
  $addNewClient.on("click", (event) => {
    event.preventDefault()
    $(".ui.modal").modal("show")
  })

  const inactive = $(".inactive")
  const showActiveOnly = $("#show-active-only")
  showActiveOnly.on("change", (e) => {
    e.preventDefault()
    if (showActiveOnly.is(":checked")) {
      inactive.hide("fast", "linear")
    } else {
      inactive.show("fast", "linear")
    }
  })

  // Show/hide the loading spinner and search results appropriately
  const setIsLoading = (isLoading) => {
    const spinner = $("#clients-search-spinner")
    const results = $("#clients-results")
    const content = $(".customers")

    const disabled = "disabled"
    const active = "active"
    if (isLoading) {
      content.unbind("click")
      results.css({ opacity: 0.5 })
      spinner.removeClass(disabled)
      spinner.addClass(active)
    } else {
      content.bind("click")
      results.css({ opacity: 1 })
      spinner.removeClass(active)
      spinner.addClass(disabled)
    }
  }

  const showOrHideNoClientsMessages = () => {
    const cards_containers = $(".cards-container")
    let active_clients
    let inactive_clients
    if ($(cards_containers[0]).hasClass("inactive")) {
      active_clients = $(cards_containers[1])
      inactive_clients = $(cards_containers[0])
    } else {
      active_clients = $(cards_containers[0])
      inactive_clients = $(cards_containers[1])
    }

    const noActiveMessageId = "no-active-clients-message"
    const noActiveIdSelector = `#${noActiveMessageId}`
    const noInactiveMessageId = "no-inactive-clients-message"
    const noInactiveIdSelector = `#${noInactiveMessageId}`

    let hasNoActiveClients = false
    let hasNoInactiveClients = false

    // Exclude the Message itself when counting children
    if (active_clients.children().not(noActiveIdSelector).length === 0) {
      hasNoActiveClients = true
    }
    if (inactive_clients.children().not(noInactiveIdSelector).length === 0) {
      hasNoInactiveClients = true
    }

    if (hasNoActiveClients) {
      if ($(noActiveIdSelector).length === 0) {
        $(
          `<div class="column" id="${noActiveMessageId}"><span>No Active clients.</span></div>`
        ).appendTo(active_clients)
      }
    } else {
      if ($(noActiveIdSelector).length > 0) {
        $(noActiveIdSelector).remove()
      }
    }

    if (hasNoInactiveClients) {
      if ($(noInactiveIdSelector).length === 0) {
        $(
          `<div class="column" id="${noInactiveMessageId}"><span>No Inactive clients.</span></div>`
        ).appendTo(inactive_clients)
      }
    } else {
      if ($(noInactiveIdSelector).length > 0) {
        $(noInactiveIdSelector).remove()
      }
    }
  }

  const searchInputSelector = "#search-clients-input"
  const val = $(searchInputSelector).val()
  $(searchInputSelector).val(val.replace("/", "")) // don't know why this happens, but...

  $("#submit-client-search-button").on("click", () => {
    setIsLoading(true)
  })

  $("#reset-client-search-results-button").on("click", (e) => {
    e.preventDefault()
    $(searchInputSelector).val("")
    $("#submit-client-search-button").click()
  })

  // Listen for client is_active toggles
  const clientUpdatedEvent = "customer_was_updated"
  document.addEventListener(clientUpdatedEvent, function (e) {
    const toggle = $(`#${e.detail.toggleId}`)
    const client_card = toggle.parents("[id^=client-card-]")[0]
    const single_card_container = $(client_card).parent()
    const cards_container = $(single_card_container).parent()
    const other_cards_container = $(cards_container).siblings(
      ".cards-container"
    )[0]

    $(single_card_container).hide("fast", "linear", function () {
      const moving = $(single_card_container).detach()
      $(moving).appendTo(other_cards_container)
      $(moving).show("fast", "linear")
      showOrHideNoClientsMessages()
    })
  })

  const toggle_checkboxes = $("[id^=toggle_professional_is_active]")
  $(toggle_checkboxes).on("change", (e) => {
    e.preventDefault()

    const checkbox = $(e.target)[0] // this checkbox input
    const { url, method } = checkbox.dataset
    const name = checkbox.name
    const val = checkbox.checked // we're inside onChange, this is the NEW value
    const body = { [name]: val }

    // Pull this up if we need to reuse AJAX
    $.ajax({
      url: url,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
      dataType: "json",
      // <%= csrf_meta_tag %> equivalent
      beforeSend: function (xhr) {
        checkbox.disabled = true
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        )
      },
      complete: function (xhr) {
        // if we failed, force an undo
        if (xhr.status !== 200) {
          checkbox.checked = !checkbox.checked
        } else {
          // Trigger Event to move partial to correct (in)/active section
          const event = new CustomEvent(clientUpdatedEvent, {
            detail: {
              toggleId: checkbox.id,
              isNowActive: val,
            },
          })
          document.dispatchEvent(event)
        }
        checkbox.disabled = false
      },
    })
  })

  // for initial render
  showOrHideNoClientsMessages()
  if (!screenResponsiveness.isMobile) {
    setIsLoading(true)
    setClientsContainerHeight()
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }
  if (!screenResponsiveness.isComputer) {
    // some mobile styling
    if (screenResponsiveness.isMobile) {
      // mobile only
      $("<br/>").insertBefore($addNewClient.siblings(".switch"))
    } else {
      $addNewClient.parent().css("margin-top", "1.5em")
    }
    $addNewClient.parent().css({ width: "100%" })
    $addNewClient.css({ float: "right" })
    if (screenResponsiveness.isMobile)
      $addNewClient.css({ "margin-top": "-1.5em" })
    setIsLoading(false)
  } else setIsLoading(false)
})
