// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const jQuery = require("jquery")
// necessary for cocoon to recognize jquery
window.jQuery = jQuery
window.$ = jQuery
require("@nathanvda/cocoon")
require("semantic-ui-sass")

import SlimSelect from 'slim-select'

// import { loadStripe } from '@stripe/stripe-js';
// const stripe = loadStripe('pk_test_51IoAhUHpJAj24WMJE4nTQ5u5Bp0wtVVP23uDStzqvNBKNxhnhqj4vvcYF141k99tOWBIyFLFWYtZ6ig5qmb7vXru00Im2kFiNd');
// explicitly require our page-specific js
// NOTE: all page event listeners are attached to DOM on-render,
//       but only get run once on their specific page (see guards)
//       ... added some console.log's for initial qa
require("./guarded")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("turbolinks:load", function () {
  // SemanticUI dropdown activation:
  $(".ui.dropdown").dropdown()

  new SlimSelect({
    select: '#language-select',
    beforeOpen: function() {
      this.data.content.style.width = '300px'; // specify your width here
    }
  });

  // Disable submits until email and passwords are filled out
  const submitButton = $(".submit-button")
  const authInputs = $("input[type=email], input[type=password]")

  authInputs.each((index, elem) => {
    $(elem).on("change input textInput", () => {
      const initial = index === 0 ? elem.value !== "" : true
      const authInputsNonEmpty = $(authInputs)
        .toArray()
        .reduce((nonEmpty, input) => nonEmpty && input.value !== "", initial)
      if (authInputsNonEmpty) {
        submitButton.prop("disabled", false)
        submitButton.fadeTo("fast", 1)
      } else {
        submitButton.prop("disabled", true)
        submitButton.fadeTo("fast", 0.3)
      }
    })
  })
})
