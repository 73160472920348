$(document).on("turbolinks:load", function () {
  if ($("#industry-preferences").length === 0) return
  console.log("registration/industry_preferences.js got past the guard!")

  const submitButton = $("#continue")
  const validateForm = () => {
    let industryCategorySelected =
      $(":radio:checked.preferred-industry-group").length === 1
    let industryStartYearSelected =
      $("#industry-category-start-year select").val() !== ""
    let compensationFormatsSelected =
      $("#compensation-formats-selection :checkbox:checked").length > 0

    return (
      industryCategorySelected &&
      industryStartYearSelected &&
      compensationFormatsSelected
    )
  }
  const disableSubmit = () => {
    if (!submitButton.prop("disabled")) {
      submitButton.prop("disabled", true)
      submitButton.fadeTo("fast", 0.3)
    }
  }
  const enableSubmit = () => {
    submitButton.prop("disabled", false)
    submitButton.fadeTo("fast", 1)
  }
  if (!validateForm()) {
    disableSubmit()
  }
  if ($("div#practice-areas-selection :checkbox:checked").length >= 5) {
    $("div#practice-areas-selection :checkbox:not(:checked)").attr(
      "disabled",
      true
    )
  }

  $(".preferred-industry-group").on("change", (e) => {
    if (e.currentTarget.value === "Insurance") {
      $(".subinsurance-section").show()
    } else {
      $(".subinsurance-section").hide()
    }

    const isOther = e.currentTarget.value === "other"
    const otherRadioId = "#other_industry_category_other"
    const $otherSelection = $(
      "#other-industry-category-section .search.selection.dropdown"
    )
    if (isOther) {
      $(".preferred-industry-group").not(otherRadioId).prop("checked", false)
      $otherSelection.focus()
      $otherSelection.removeClass("disabled")
    } else {
      $(otherRadioId).prop("checked", false)
      $otherSelection.blur()
      $otherSelection.addClass("disabled")
    }
  })

  if ($("#other_industry_category_other").prop("checked"))
    $(
      "#other-industry-category-section .search.selection.dropdown"
    ).removeClass("disabled")

  // wait for Semantic to mount the input
  setTimeout(() => {
    const $otherIndustryDropdownContainer = $(
      "#other-industry-category-section .search.selection.dropdown"
    )

    const $currentPreference = $("#current-preference")
    const $newIndustryCategory = $("#new-industry-category")
    if ($currentPreference.length > 0) {
      $newIndustryCategory.removeClass("active selected")
      $currentPreference.addClass("active selected")
      $otherIndustryDropdownContainer
        .find(".text")
        .addClass("default")
        .text($currentPreference.text())
    }

    const inputSearch = $otherIndustryDropdownContainer.find("input.search")
    $(inputSearch).on("change input textInput", function (e) {
      const val = e.target.value
      if (val.length > 0) {
        const capitalized = val
          .split(" ")
          .map(
            (sub) =>
              `${sub.length > 0 ? sub[0].toUpperCase() : ""}${
                sub.length > 1 ? sub.slice(1) : ""
              }`
          )
          .join(" ")
        $("#new-industry-category").text(`${capitalized} (Create New)`)
      } else $("#new-industry-category").text("")
    })
  }, 200)

  // hide the sub insurance section checkboxes by default,
  // unless our preferred category is insurance
  if ($("#profile_preferred_industry_category_insurance").is(":checked")) {
    $(".subinsurance-section").show()
  } else {
    $(".subinsurance-section").hide()
  }

  $("div#practice-areas-selection :checkbox").change(function () {
    const areaName = this.value
    const areaNameParametrized = this.dataset.areaNameParametrized
    const timeId = new Date().getTime()

    if (this.checked) {
      if ($("div#practice-areas-selection :checkbox:checked").length >= 5) {
        $("div#practice-areas-selection :checkbox:not(:checked)").attr(
          "disabled",
          true
        )
      }

      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 0) {
        $(this).before(
          `<input type='hidden' id='practice-area-name-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_name]' value='${areaName}' />`
        )
        $(this).before(
          `<input type='hidden' id='practice-area-type-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_type]' value='Practice' />`
        )
      } else {
        $(this)
          .siblings(`#practice-area-destroy-${areaNameParametrized}`)
          .remove()
      }
    } else {
      if ($("div#practice-areas-selection :checkbox:checked").length < 5) {
        $("div#practice-areas-selection :checkbox").removeAttr("disabled")
      }

      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 1) {
        const areaId = $(this).siblings(`#${areaNameParametrized}-id`).val()
        $(this).before(
          `<input type='hidden' id='practice-area-destroy-${areaNameParametrized}' name='profile[industry_areas_attributes][${areaId}][_destroy]' value='1' />`
        )
      } else {
        $(this).siblings(`input[type='hidden']`).remove()
      }
    }
  })

  //General Market Focus Areas
  if (
    $("div#general-market-focus-areas-selection :checkbox:checked").length >= 3
  ) {
    $("div#general-market-focus-areas-selection :checkbox:not(:checked)").attr(
      "disabled",
      true
    )
  }

  $("div#general-market-focus-areas-selection :checkbox").change(function () {
    const areaName = this.value
    const areaNameParametrized = this.dataset.areaNameParametrized
    const areaRequiresAmount = this.dataset.requiresAmount
    const timeId = new Date().getTime()
    if (this.checked) {
      if (
        $("div#general-market-focus-areas-selection :checkbox:checked")
          .length >= 3
      ) {
        $(
          "div#general-market-focus-areas-selection :checkbox:not(:checked)"
        ).attr("disabled", true)
      }

      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 0) {
        $(this).before(
          `<input type='hidden' id='general-market-focus-area-name-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_name]' value='${areaName}' />`
        )
        $(this).before(
          `<input type='hidden' id='general-market-focus-area-type-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_type]' value='General Market Focus' />`
        )
        if (areaRequiresAmount === "true") {
          $(this).after(
            `<input type='text' id='general-market-focus-area-amount-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][associated_area_amount]' placeholder='Enter amount' />`
          )
        }
      } else {
        $(this)
          .siblings(
            `#general-market-focus-area-destroy-${areaNameParametrized}`
          )
          .remove()
        $(this).siblings("input[type='text']").show()
      }
    } else {
      if (
        $("div#general-market-focus-areas-selection :checkbox:checked").length <
        3
      ) {
        $("div#general-market-focus-areas-selection :checkbox").removeAttr(
          "disabled"
        )
      }

      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 1) {
        const areaId = $(this).siblings(`#${areaNameParametrized}-id`).val()
        $(this).before(
          `<input type='hidden' id='general-market-focus-area-destroy-${areaNameParametrized}' name='profile[industry_areas_attributes][${areaId}][_destroy]' value='1' />`
        )
        $(this).siblings("input[type='text']").hide()
      } else {
        $(this).siblings(`input[type='hidden']`).remove()
        $(this).siblings(`input[type='text']`).remove()
      }
    }
  })

  //Niche Market Areas
  if ($("div#niche-market-areas-selection :checkbox:checked").length >= 3) {
    $("div#niche-market-areas-selection :checkbox:not(:checked)").attr(
      "disabled",
      true
    )
  }

  $("div#niche-market-areas-selection :checkbox").change(function () {
    const areaName = this.value
    const areaNameParametrized = this.dataset.areaNameParametrized
    const timeId = new Date().getTime()

    if ($("div#niche-market-areas-selection :checkbox:checked").length >= 3) {
      $("div#niche-market-areas-selection :checkbox:not(:checked)").attr(
        "disabled",
        true
      )
    } else {
      $("div#niche-market-areas-selection :checkbox").removeAttr("disabled")
    }

    if (this.checked) {
      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 0) {
        $(this).before(
          `<input type='hidden' id='niche-market-area-name-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_name]' value='${areaName}' />`
        )
        $(this).before(
          `<input type='hidden' id='niche-market-area-type-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][area_type]' value='Niche Market' />`
        )
        if (areaName === "Other") {
          $(this).after(
            `<input type='text' id='niche-market-area-amount-${areaNameParametrized}' name='profile[industry_areas_attributes][${timeId}][other_area_name]' placeholder='Please specify' />`
          )
        }
      } else {
        $(this)
          .siblings(`#niche-market-area-destroy-${areaNameParametrized}`)
          .remove()
        $(this).siblings("input[type='text']").show()
      }
    } else {
      if ($(this).siblings(`#${areaNameParametrized}-id`).length === 1) {
        const areaId = $(this).siblings(`#${areaNameParametrized}-id`).val()
        $(this).before(
          `<input type='hidden' id='niche-market-area-destroy-${areaNameParametrized}' name='profile[industry_areas_attributes][${areaId}][_destroy]' value='1' />`
        )
        $(this).siblings("input[type='text']").hide()
      } else {
        $(this).siblings(`input[type='hidden']`).remove()
        $(this).siblings(`input[type='text']`).remove()
      }
    }
  })

  $(
    ":radio.preferred-industry-group, #compensation-formats-selection :checkbox, #industry-category-start-year select"
  ).on("change", () => {
    if (validateForm()) {
      enableSubmit()
    } else {
      disableSubmit()
    }
  })

  //On submit
  $("form#industry-preferences").submit(function () {
    let compensationFormats = ""
    $("#compensation-formats :checkbox:checked").each(function () {
      compensationFormats += `${$(this).val()},`
    })

    $(this).append(
      `<input type="hidden" name="profile[compensation_format]" value="${compensationFormats}" />`
    )
  })
})
