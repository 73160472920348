require("./education_certifications")
require("./firm_information")
require("./industry_preferences")
require("./new_user")
require("./personal_information")
require("./subscribe")

$(document).on("turbolinks:load", function () {
  if ($(".registration").length === 0) return
  console.log("registration/index.js got past the guard!")

  // Dynamic Progress Indicator styling:
  // ... keeping for the moment in case we need to put something in here
  $("#registration-progress-bar").progress({
    text: {
      active: "Step {value} of {total}",
    },
    showActivity: false,
    label: "",
  })
})
