import { addAsterisksToReqFields } from "./recipes"

$(document).on("turbolinks:load", function () {
  if ($("#firm-information").length === 0) return
  console.log("registration/firm_information.js got past the guard!")

  const submitButton = $("#continue")
  const requiredFields = $(
    "#firm-name, #firm-city, #employment-title, #employment-start-date-year, #employment-start-date-month"
  )
  addAsterisksToReqFields(requiredFields)

  const disableSubmit = () => {
    if (!submitButton.prop("disabled")) {
      submitButton.prop("disabled", true)
      submitButton.fadeTo("fast", 0.3)
    }
  }

  const enableSubmit = () => {
    submitButton.prop("disabled", false)
    submitButton.fadeTo("fast", 1)
  }

  const validateForm = () => {
    const requiredFieldsArray = $(requiredFields).toArray()
    const requiredFieldsValid = requiredFieldsArray.reduce(
      (nonEmpty, field) => nonEmpty && field.value !== "",
      true
    )
    return requiredFieldsValid
  }

  requiredFields.each((index, elem) => {
    if (elem.value == "") {
      disableSubmit()
    }
    $(elem).on("change input textInput", (e) => {
      if (validateForm()) {
        enableSubmit()
      } else {
        disableSubmit()
      }
    })
  })
})
