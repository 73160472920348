$(document).on("turbolinks:load", function () {
  // guard for not being on /clients#show
  if ($(".client-show-page-content").length === 0) return
  console.log("customers/show.js got past the guard!")

  $(".add-client-rep").on("click", (e) => {
    const id = e.currentTarget.getAttribute("professional-id")
    $(`#aptcn-modal-${id}`).modal({ inverted: true }).modal("show")
  })

  $(".delete-client-need").on("click", (event) => {
    $(
      `#delete-client-need-${event.currentTarget.attributes["client-need-id"].value}`
    )
      .modal({ inverted: true })
      .modal("show")
  })

  $(".update-client-need").on("click", (event) => {
    $(
      `#update-client-need-${event.currentTarget.attributes["client-need-id"].value}`
    )
      .modal({ inverted: true })
      .modal("show")
  })

  $(".delete-client-need-professional").on("click", (event) => {
    $(
      `#delete-client-need-professional-${event.currentTarget.attributes["professional-id"].value}`
    )
      .modal({ inverted: true })
      .modal("show")
  })

  $(".delete-client").on("click", (event) => {
    $(`#delete-client-${event.currentTarget.attributes["client-id"].value}`)
      .modal({ inverted: true })
      .modal("show")
  })

  $(".update-client").on("click", (event) => {
    $(`#update-client-${event.currentTarget.attributes["client-id"].value}`)
      .modal({ inverted: true })
      .modal("show")
  })

  $(".list-changer").on("click", (event) => {
    const listType = event.currentTarget.innerText
    $("#list_type").val(listType.toLowerCase())
    $("#professional-list-form").trigger("submit")
  })

  $(".cancel-confirm-delete").on("click", (event) => {
    $(".ui.tiny.modal.delete-confirmation-modal").modal("hide")
  })

  $(".cancel-confirm-update").on("click", (event) => {
    $(".ui.tiny.modal.update-client-need-modal").modal("hide")
  })

  $(".submit-cn-modal").on("click", (event) => {
    $(`#${event.currentTarget.attributes["form-id"].value}`).trigger("submit")
  })

  $(".client-need-item").on("click", (event) => {
    const profileId = event.currentTarget.attributes["profile-id"].value
    const clientNeedId = event.currentTarget.attributes["client-need-id"].value
    $(`#client_need_id_${profileId}`).val(clientNeedId)
    $(`#create-client-rep-form_${profileId}`).trigger("submit")
  })

  $("#search_professional").on("keyup", function (e) {
    if (timeout) {
      clearTimeout(timeout)
    }

    const timeout = setTimeout(function () {
      $("#professional-list-form").trigger("submit")
    }, 1500)
  })
})
