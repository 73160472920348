import "inputmask/dist/jquery.inputmask"
import { addAsterisksToReqFields } from "./recipes"

$(document).on("turbolinks:load", function () {
  if ($("#personal-information").length === 0) return
  console.log("registration/personal_information.js got past the guard!")

  $(".phone-number").inputmask("(999) 999-9999", { removeMaskOnSubmit: true })
  const submitButton = $("#continue")
  const requiredInputs = $(
    "input.phone-number, input#residence-city, select#residence-state, input.first-name, input.last-name"
  )
  addAsterisksToReqFields(requiredInputs)

  $("#resetDropdownBtn").on("click", function (event) {
    event.preventDefault()

    // Get the dropdown element
    const dropdown = document.getElementById("profile_dna_style")

    // Reset the selected index to default (0)
    dropdown.selectedIndex = 0

    $(".style-dropdown").dropdown("clear")

    // Trigger a change event on the dropdown to update the hidden form value
    const changeEvent = new Event("change", { bubbles: true })
    dropdown.dispatchEvent(changeEvent)
  })

  const disableSubmit = () => {
    if (!submitButton.prop("disabled")) {
      submitButton.prop("disabled", true)
      submitButton.fadeTo("fast", 0.3)
    }
  }
  const enableSubmit = () => {
    submitButton.prop("disabled", false)
    submitButton.fadeTo("fast", 1)
  }
  const validateFields = () => {
    const requiredInputsArray = $(requiredInputs).toArray()
    const requiredInputsNonEmpty = requiredInputsArray.reduce(
      (nonEmpty, input) => nonEmpty && input.value !== "",
      true
    )
    return requiredInputsNonEmpty
  }

  requiredInputs.each((index, elem) => {
    if (elem.value == "") {
      disableSubmit()
    }
    $(elem).on("change input textInput", (e) => {
      if (validateFields()) {
        enableSubmit()
      } else {
        disableSubmit()
      }
    })
  })

  const radioInput = $("input[type='radio'].languages-besides-english")
  const languageList = $("#languages-list-section")
  const yesContainer = $("#yes-other-languages-container")
  const noContainer = $("#no-other-languages-container")

  radioInput.on("change", function (event) {
    if (event.target.value === "yes") {
      yesContainer.addClass("checked")
      noContainer.removeClass("checked")
      languageList.addClass("showing")
    } else if (event.target.value === "no") {
      noContainer.addClass("checked")
      yesContainer.removeClass("checked")
      languageList.removeClass("showing")
    }
  })

  $("form#personal-information").submit(function (event) {
    if ($("#languages_besides_english_no:checked").length === 1) {
      $("input#profile_languages").val(null)
    }
  })

  // apply dynamic styling
  if (!noContainer.hasClass("checked")) {
    noContainer.addClass("checked")
  }
})
