$(document).on("turbolinks:load", function () {
  if ($(".dna-page-content").length === 0) {
    return
  }

  console.log("dna.js got past the guard!")

  $(".question-answer").on("click", (e) => {
    const valueName = e.currentTarget.name
    const valueChecked = e.currentTarget.value
    const metaQuestion = e.currentTarget.parentElement.parentElement.getAttribute(
      "meta-question"
    )
    const metaQuestionSection = $(`section[question-group=${metaQuestion}]`)
    const sectionRadios = metaQuestionSection.find("input[type=radio]")

    sectionRadios.each((sr) => {
      const srNode = sectionRadios[sr]
      if (srNode.name != valueName) {
        const checkedInput = $(`input[name='${srNode.name}']:checked`)
        if (checkedInput.val() == valueChecked) {
          checkedInput.prop("checked", false)
        }
      }
    })
  })

  // paint radio Buttons
  function paintButtons() {
    $("input[type=radio]").each((i, radio) => {
      if (radio.checked) {
        $(radio).parent().addClass("checked")
      } else {
        $(radio).parent().removeClass("checked")
      }
    })
  }

  const setIsLoading = (isLoading) => {
    const spinner = $("#dna-submit-spinner")
    const content = $(".dna-page-content")

    const disabled = "disabled"
    const active = "active"
    if (isLoading) {
      content.unbind("click")
      content.css({ opacity: 0.5 })
      spinner.removeClass(disabled)
      spinner.addClass(active)
    } else {
      content.bind("click")
      content.css({ opacity: 1 })
      spinner.removeClass(active)
      spinner.addClass(disabled)
    }
  }

  // Disable Submit until two radio buttons from each question section are checked
  // (ie the User has given a "Most" and "Least" Answer for each question)
  const submitButton = $(".continue-button")

  submitButton.on("click", () => {
    setIsLoading(true)
  })
  // Require 1 "Most" + 1 "Least" from each section
  const requiredNumCheckedRadios = $("section").length * 2
  const radioInputs = $("input[type=radio]")
  $(radioInputs).on("change", function () {
    paintButtons()

    const numCheckedRadios = $("input[type=radio]:checked").length

    if (numCheckedRadios < requiredNumCheckedRadios) {
      if (!submitButton.prop("disabled")) {
        submitButton.prop("disabled", true)
        submitButton.fadeTo("fast", 0.3)
      }
    } else {
      if (submitButton.prop("disabled")) {
        submitButton.prop("disabled", false)
        submitButton.fadeTo("fast", 1)
      }
    }
  })

  //initial Render
  paintButtons()
  setIsLoading(false)
})
