import { addAsterisksToReqFields } from "./recipes"

$(document).on("turbolinks:load", function () {
  if ($("#new-user").length === 0) return
  console.log("registration/new_user.js got past the guard!")

  const submitButton = $(".continue-button")
  const requiredInputs = $(
    "input#user_profile_attributes_first_name, input#user_profile_attributes_last_name, input#user_email, input#user_password, input#user_password_confirmation"
  )
  addAsterisksToReqFields(requiredInputs)

  const termsCheckbox = $("input:checkbox#user_terms")
  const disableSubmit = () => {
    if (!submitButton.prop("disabled")) {
      submitButton.prop("disabled", true)
      submitButton.fadeTo("fast", 0.3)
    }
  }

  const enableSubmit = () => {
    submitButton.prop("disabled", false)
    submitButton.fadeTo("fast", 1)
  }
  const checkInputs = () => {
    const requiredInputsArray = $(requiredInputs).toArray()
    const requiredInputsNonEmpty = requiredInputsArray.reduce(
      (nonEmpty, input) => nonEmpty && input.value !== "",
      true
    )
    return requiredInputsNonEmpty && termsCheckbox.is(":checked")
  }
  requiredInputs.each((index, elem) => {
    if (elem.value == "") {
      disableSubmit()
    }
    $(elem).on("change input textInput", (e) => {
      if (checkInputs()) {
        enableSubmit()
      } else {
        disableSubmit()
      }
    })
  })
  termsCheckbox.change(function () {
    if (checkInputs()) {
      enableSubmit()
    } else {
      disableSubmit()
    }
  })
  $("form#new_user").submit(function (event) { })
})
