// Responsiveness:
// Ensure below breakpoints match those in application.css.scss
const isMobile = window.matchMedia(
  "screen and (min-width : 320px) and (max-width : 767px)"
).matches
const isTablet = window.matchMedia(
  "screen and (min-width : 768px) and (max-width : 1023px)"
).matches
const isComputer = window.matchMedia("screen and (min-width: 1024px").matches

const lockOrientationUniversal =
  // screen.msLockOrientation ||
  // screen.lockOrientation || // bool
  // screen.mozLockOrientation ||
  screen.orientation && screen.orientation.lock // Promise

$(document).on("turbolinks:load", function () {
  if (isMobile) console.log("Mobile dimensions detected.")
  if (isTablet) console.log("Tablet dimensions detected.")
  if (isComputer) console.log("Computer dimensions detected.")
  if (!isMobile && !isTablet && !isComputer) {
    console.log("Screen size not accounted for!!!")
  } else {
    const mobile = "Mobile"
    const tabletOrComputer = "Tablet/Computer"

    const portrait = "portrait-primary"
    const landscape = "landscape-primary"

    const succeeded = (screenSize, orientation) =>
      `${screenSize} screen locked in ${orientation}.`
    const failed = (screenSize, orientation) =>
      `${screenSize} screen failed to orient ${orientation}`

    const logMobileSucceeded = () => console.log(succeeded(mobile, portrait))
    const logMobileFailed = () => console.log(failed(mobile, portrait))

    const logTabletOrCompSucceeded = () =>
      console.log(succeeded(tabletOrComputer, landscape))
    const logTabletOrCompFailed = () =>
      console.log(failed(tabletOrComputer, landscape))

    if (isMobile) {
      if (!lockOrientationUniversal) {
        logMobileFailed()
      } else if (lockOrientationUniversal === screen.orientation.lock) {
        lockOrientationUniversal(portrait)
          .then(() => logMobileSucceeded())
          .catch(() => logMobileFailed())
        // } else if (lockOrientationUniversal(portrait)) {
        //   logMobileSucceeded()
      } else {
        logMobileFailed()
      }
    }
    if (isTablet || isComputer) {
      if (!lockOrientationUniversal) {
        logTabletOrCompFailed()
      } else if (lockOrientationUniversal === screen.orientation.lock) {
        lockOrientationUniversal(landscape)
          .then(() => logTabletOrCompSucceeded())
          .catch(() => logTabletOrCompFailed())
        // } else if (lockOrientationUniversal(landscape)) {
        //   logTabletOrCompSucceeded()
      } else {
        logTabletOrCompFailed()
      }
    }
  }

  // Dynamic Navbar styling:
  const navbarHeight = $(".navbar").outerHeight()
  const paddedNavbarHeight = navbarHeight + 10
  const footerHeight = $(".footer").outerHeight()
  const combined = paddedNavbarHeight + footerHeight

  const $pageContent = $(".page-content")
  $pageContent.css("margin-top", `${paddedNavbarHeight}px`)

  if (isMobile) {
    // Moves the footer to the very bottom of the scroll
    $pageContent.css("min-height", `calc(100% - ${combined}px)`)
  } else {
    // keeps the navbar always visible on the bottom of the viewport
    $pageContent.css("height", `calc(100% - ${combined}px)`)
    $pageContent.css("padding-bottom", "2em")
  }
})

export const screenResponsiveness = {
  isMobile,
  isTablet,
  isComputer,
}
