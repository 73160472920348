import { loadStripe } from "@stripe/stripe-js"

$(document).on("turbolinks:load", async function () {
  if ($("div#subscribe-content").length === 0) return
  console.log("registration/subscribe.js got past the guard!")

  const $radios = $("input[type=radio]")
  const styleRadioOptions = () => {
    $radios.each((i, e) => {
      if (e.checked) {
        $(e).parent().css({
          border: "3px solid #15aa58",
          "background-color": "#fefefa",
        }) // $green, $off-white
      } else {
        $(e).parent().css({
          border: "1px solid transparent",
          "background-color": "transparent",
        })
      }
    })
  }
  $radios.on("change", styleRadioOptions)
  const stripePublishableKey = $("#stripe-publishable-key").data(
    "stripe-publishable-key"
  )
  const submitButton = $("button#subscribe")
  const stripe = await loadStripe(stripePublishableKey)
  const elements = stripe.elements()
  const cardElement = elements.create("card")
  cardElement.mount("#card-input")
  cardElement.on("change", ({ error }) => {
    if (error) {
      const $cardErrors = $("#card-errors")
      $cardErrors.show()
      $cardErrors.html(error.message)
    } else {
      $("#card-errors").hide()
    }
    submitButton.disabled = false
  })

  $("form#subscribe-form").submit(async function (event) {
    event.preventDefault()
    submitButton.disabled = true

    const result = await stripe.createToken(cardElement)
    if (result.error) {
      $("#card-errors").show()
      submitButton.disabled = false
    } else {
      $("#card-token").val(result.token.id)
      $(this).off()
      $("form#subscribe-form").submit()
    }
  })

  // On-Render
  styleRadioOptions()

  const hashCode = function (str) {
    let strToHash = str.toLowerCase()
    let hash = 0,
      i,
      chr
    if (strToHash.length === 0) return hash
    for (i = 0; i < strToHash.length; i++) {
      chr = strToHash.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }

    return hash
  }

  const $coupon = $("#coupon-code")
  $coupon.on("change", (evt) => {
    const couponCode = evt.target.value
    if (hashCode(couponCode) !== 92670863) {
      $coupon.addClass("error")
    } else {
      $coupon.removeClass("error")
    }
  })
})
