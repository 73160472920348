import { screenResponsiveness } from "../../responsive"

$(document).on("turbolinks:load", function () {
  if ($(".professionals-page-content").length === 0) return
  console.log("professionals/filterbar.js got past the guard!")

  function setFilterBarSectionHeight() {
    setTimeout(() => {
      const contentHeight = Number($(".page-content").height())
      const submitButtonHeight = $("#filter_form_submit_main").outerHeight()
      $("#filterbar-sections").css(
        "height",
        `calc(${contentHeight - submitButtonHeight}px - 2em)`
      )
    }, 200)
  }

  function setResultsSectionHeight() {
    setTimeout(() => {
      const contentHeight = Number($(".page-content").height())
      const searchControlsHeight = $(".search-section").outerHeight()
      $(".professionals-results").css(
        "height",
        `calc(${contentHeight - searchControlsHeight}px - 2em)`
      )
    }, 200)
  }

  function setCardsEqualHeight() {
    setTimeout(() => {
      const maxHeightReducer = (maxHeight, elem) =>
        $(elem).outerHeight() >= maxHeight ? $(elem).outerHeight() : maxHeight
      const $cards = $(".professionals-results .card")
      const maxCardHeight = $cards.toArray().reduce(maxHeightReducer, 0)
      $cards.animate({ height: `${maxCardHeight}px` }, "fast")
    }, 200)
  }

  function setCardsEqualWidth() {
    setTimeout(() => {
      const minWidthReducer = (minWidth, elem) =>
        $(elem).outerWidth() <= minWidth ? $(elem).outerWidth() : minWidth

      const $columns = $(".professionals-results").children()
      const columnsArray = $columns.toArray()
      if (columnsArray.length > 2) {
        const minColumnWidth = columnsArray.reduce(minWidthReducer, 9999)
        const minColumnWidthPxls = `${minColumnWidth}px`
        $columns.animate({ "max-width": minColumnWidthPxls }, "fast")
      } else {
        $columns.animate({ "max-width": "320px" }, "fast")
      }
    }, 200)
  }

  // updates the selected count in each viewable section header
  const updateCheckedCount = function () {
    $(".filterbar-list").each((idx, element) => {
      const checkedCount = $(element).find("input:checked").length
      const selectedCount = $(element).find("option:selected").length
      const isMultiSelectFilter = $(element).find("select").length > 0

      const count = isMultiSelectFilter ? selectedCount : checkedCount

      const sectionHeaderTextSpan = $(element)
        .parents(".section-area")
        .find("span")
        .first() // Location
      const sectionHeaderTextSpanAreaName = sectionHeaderTextSpan.attr("area")

      if (count > 0) {
        sectionHeaderTextSpan.text(
          `${sectionHeaderTextSpanAreaName} (${count} selected)`
        )
      } else {
        sectionHeaderTextSpan.text(sectionHeaderTextSpanAreaName)
      }
    })
  }

  // what you do to one checkbox, we need to do to its counterpart
  $(".filter-option").change((e) => {
    const oppositeListType =
      e.target.getAttribute("filter_list") === "main" ? "sidebar" : "main"
    const baseId = e.target.id.slice(0, e.target.id.lastIndexOf("_")) // removes the list type from the id
    const oppositeElement = $(`#${baseId}_${oppositeListType}`) // selects the element on other list
    const newValue = !oppositeElement.prop("checked") // get the inverted value
    oppositeElement.prop("checked", newValue)

    // run through the counts so both lists counts are accurate
    updateCheckedCount()
  })

  function getOppositeSelectOption(selectOption) {
    const oppositeListType =
      selectOption.attr("filter_list") === "main" ? "sidebar" : "main"
    const baseId = selectOption
      .attr("id")
      .slice(0, selectOption.attr("id").lastIndexOf("_"))
    return $(`#${baseId}_${oppositeListType}`)
  }

  // ^Update selecting items from Semantic multiselect
  function attachSelectingListeners(items) {
    $(items).on("click", function () {
      const triggered = $(this).first()
      // just to wait for Semantic behavior to finish
      setTimeout(function () {
        const thisVal = $(triggered).attr("data-value")
        const thisSelectOption = $(
          `.multiselect-filter select option:contains(${thisVal})`
        ).first()
        const oppositeSelectOption = getOppositeSelectOption(thisSelectOption)

        thisSelectOption.attr("selected", "selected")
        oppositeSelectOption.attr("selected", "selected")
        updateCheckedCount()
        $(triggered).off("click")

        const thisSemanticLabelIcon = $(
          `.multiselect-filter a.ui.label:contains(${thisVal}) i`
        )
        attachDeselectingListeners(thisSemanticLabelIcon)
      }, 200)
    })
  }

  // ^Update De-selecting items from Semantic multiselect
  function attachDeselectingListeners(labelIcons) {
    $(labelIcons).on("click", function () {
      const triggered = $(this).first().parent()
      // just to wait for Semantic behavior to finish
      setTimeout(function () {
        const thisVal = $(triggered).attr("data-value")
        const thisSelectOption = $(
          `.multiselect-filter select option:contains(${thisVal})`
        ).first()
        const oppositeSelectOption = getOppositeSelectOption(thisSelectOption)

        thisSelectOption.removeAttr("selected")
        oppositeSelectOption.removeAttr("selected")
        updateCheckedCount()
        $(triggered).off("click")

        const thisSemanticItem = $(
          `.multiselect-filter .item:contains(${thisVal})`
        )
        attachSelectingListeners(thisSemanticItem)
      }, 200)
    })
  }

  // Show/hide the loading spinner and search results appropriately
  const setIsLoading = (isLoading) => {
    const spinner = $("#professionals-search-spinner")
    const results = $(".professionals-results")
    // had just the professional_card links disabled initially, but
    // disabling all click events is a safer option
    const content = $(".professionals-page-content")

    const disabled = "disabled"
    const active = "active"
    if (isLoading) {
      content.unbind("click")
      results.css({ opacity: 0.5 })
      spinner.removeClass(disabled)
      spinner.addClass(active)
    } else {
      content.bind("click")
      results.css({ opacity: 1 })
      spinner.removeClass(active)
      spinner.addClass(disabled)
    }
  }

  // sync up the last name search with the sidebar
  const $keywordInput = $("#keyword_input")
  $keywordInput.on("keyup", (e) => {
    const val = e.target.value
    $("#search_keyword_hidden_main").val(val.toLowerCase())
    $("#search_keyword_hidden_sidebar").val(val.toLowerCase())
  })

  // set isLoading when forms are submitted
  $("#filter_form_main").on("submit", () => {
    setIsLoading(true)
  })

  // turn default submission off
  $("#filter_form_sidebar").on("submit", (e) => {
    e.preventDefault()
  })
  // instead, handle with submit_button click so we can close sidebar smoothly
  $("#filter_form_submit_sidebar").on("click", (e) => {
    const $filterBarSidebarSection = $("#filter-bar-sidebar-section"),
      sidebarForm = $("#filter_form_sidebar")

    setIsLoading(true)

    // see: https://stackoverflow.com/questions/2000111/rails-is-not-passing-the-commit-button-parameter
    e.preventDefault() // gets rid of commit param
    sidebarForm.off("submit")
    const $filterFormSubmitSidebar = $("#filter_form_submit_sidebar")
    const commitParam = $("<input type='hidden' />")
      .attr("name", $filterFormSubmitSidebar[0].name)
      .attr("value", $filterFormSubmitSidebar[0].value)

    sidebarForm.append(commitParam) // adds back commit param

    // need to delay the form submission for the hide-sidebar transition
    if ($filterBarSidebarSection.hasClass("visible")) {
      $filterBarSidebarSection.sidebar("toggle")
      setTimeout(() => {
        sidebarForm.submit()
      }, 200)
    } else {
      sidebarForm.submit()
    }
  })

  const submitFilterForm = function () {
    $("#filter_form_submit_main, #filter_form_submit_sidebar").trigger("click")
  }

  // communicates the top filters with the hidden side bar filters
  const topFilterManagement = function (id) {
    const newValue = !$(`#${id}_toggle_check`).prop("checked")
    const $searchIdHiddenMain = $(`#search_${id}_hidden_main`)

    // quick and dirty solution to get this back up and running from refactor
    $searchIdHiddenMain.prop("checked", newValue)
    $searchIdHiddenMain.val(newValue)

    const $searchIdHiddenSidebar = $(`#search_${id}_hidden_sidebar`)
    $searchIdHiddenSidebar.prop("checked", newValue)
    $searchIdHiddenSidebar.val(newValue)
  }

  // register the click events to sync the filters with the sidebar
  $("#prev_used, #saved_only, #sort_by").on("click", (e) => {
    topFilterManagement(e.target.id)
    submitFilterForm()
  })

  // flip the arrows according to value
  $("#sort_by").each(() => {
    const newValue = $(`#sort_by_toggle_check`).prop("checked")
    $("#sort_by")
      .removeClass(newValue ? "down" : "up")
      .addClass(newValue ? "up" : "down")
  })

  $keywordInput.on("keyup", function (e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      submitFilterForm()
    }

    // commenting out timeout, leaving in for now,
    // in case we want to bring it back
    // if (timeout) { clearTimeout(timeout); }

    // const timeout = setTimeout(function () {
    //   topFilterManagement(e.target.id)
    //   // submitFilterForm()
    // }, 3000);
  })

  // set up the collapsible section changing state
  $(".section-header").on("click", (event) => {
    const currentSectionId = event.currentTarget.parentElement.id
    const $currentSectionId = $(`#${currentSectionId}`)
    $currentSectionId
      .find("span i")
      .toggleClass("angle down")
      .toggleClass("plus")
    $currentSectionId.children("div").toggleClass("showing")
  })

  // method to open add client rep popover
  $(".connection-action").on("click", (e) => {
    const id = e.currentTarget.getAttribute("profile_id")
    $(`#new-client-rep-popover-${id}`).modal({ inverted: true }).modal("show")
  })

  $(".client-rep-modal-cancel").on("click", (e) => {
    const id = e.currentTarget.getAttribute("profile_id")
    $(`#new-client-rep-popover-${id}`).modal({ inverted: true }).modal("hide")
  })

  $("#mobile-professionals-filter-button").on("click", () => {
    $(".ui.sidebar").sidebar("toggle")
  })

  $(window).resize(function () {
    $("#width_hidden").val($(window).width())
    submitFilterForm()
  })

  // filters the list of available customers to pick from
  $("input[name='search.crs']").on("keyup", function (e) {
    if (timeo) {
      clearTimeout(timeo)
    }

    const timeo = setTimeout(function () {
      filterCrs(e)
    }, 500)
  })

  // hide or show a customer in the cr list
  const filterCrs = function (e) {
    const crs = e.target.offsetParent.nextElementSibling.id
    const searchValue = e.target.value.toLowerCase()
    $(`#${crs}>.cr-list>div`).each((idx, element) => {
      const cbv = $(element).children("span").text().toLowerCase()
      if (cbv.includes(searchValue)) {
        $(element).show()
      } else {
        $(element).hide()
      }
    })
  }

  $("#reset-professional-search-results-button").on("click", (e) => {
    e.preventDefault()
    setIsLoading(true)
    // reset all of the query/filter params
    // window.location.href = window.location.pathname
    window.location.replace(window.location.pathname)
    // can use .replace() if we don't need to remember previous filters
    // NOTE: using .replace() for the time being, .href seems to give inconsistent state onBack
  })

  // On-Render
  updateCheckedCount()
  // wait for Semantic to do its DOM manipulations
  setTimeout(function () {
    attachSelectingListeners($(".multiselect-filter .item").not(".active"))
    attachDeselectingListeners($(".multiselect-filter a.ui.label i"))
  }, 200)
  if (!screenResponsiveness.isMobile) {
    setIsLoading(true)
    setFilterBarSectionHeight()
    setResultsSectionHeight()
    setCardsEqualHeight()
    setCardsEqualWidth()
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  } else {
    setIsLoading(false)
  }
})
