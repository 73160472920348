require("./filterbar")

$(document).on("turbolinks:load", function () {
  if ($("#professional-profile").length === 0) return
  console.log("professionals/index.js got past the guard!")

  // method to open add client rep popover
  $(".ui.labeled.icon.button.green").on("click", (e) => {
    const id = e.currentTarget.getAttribute("profile_id")
    $(`#new-client-rep-popover-${id}`).modal({ inverted: true }).modal("show")
  })

  $(".client-rep-modal-cancel").on("click", (e) => {
    const id = e.currentTarget.getAttribute("profile_id")
    $(`#new-client-rep-popover-${id}`).modal({ inverted: true }).modal("hide")
  })
})
