import { screenResponsiveness } from "../responsive"

$(document).on("turbolinks:load", function () {
  if ($(".inbox-page-content").length === 0) return
  console.log("inbox.js got past the guard!")

  function setInboxContainerHeight() {
    setTimeout(() => {
      const contentHeight = Number($(".page-content").height())
      const headingHeight = $("h2").outerHeight()
      const filterOptionsHeight = $("#messages_search_form").outerHeight()
      $(".inbox-container").css(
        "height",
        `${contentHeight - headingHeight - filterOptionsHeight}px`
      )
    }, 200)
  }

  $(".interested.form-action").on("click", (e) => {
    $(e.currentTarget.previousElementSibling).val(true)
    $(e.currentTarget.parentElement).trigger("submit")
  })

  $(".not-interested.form-action").on("click", (e) => {
    $(e.currentTarget.previousElementSibling.previousElementSibling).val(false)
    $(e.currentTarget.parentElement).submit()
  })

  $("#sort_by_name_icon, #sort_by_date_icon").on("click", (e) => {
    const currentTargetId = e.currentTarget.id.slice(
      0,
      e.currentTarget.id.length - 5
    )
    let siblingTarget
    if (currentTargetId === "sort_by_name") {
      siblingTarget = "sort_by_date"
    } else {
      siblingTarget = "sort_by_name"
    }

    // down = a-z or desc, up = z-a or asc
    const newDirection = e.currentTarget.className.includes("down")
      ? "asc"
      : "desc"
    $(`#${currentTargetId}`).val(newDirection)
    $(`#${siblingTarget}`).val(null)
    $("#messages_search_form").submit()
  })

  $("#message_search_input").on("keyup", function () {
    if (timeout) {
      clearTimeout(timeout)
    }

    // hmm not sure if this is hoisted outside of this block to be captured by above^
    // ESLint doesn't think so...
    const timeout = setTimeout(function () {
      $("#messages_search_form").submit()
    }, 3000)
  })

  if (!screenResponsiveness.isMobile) {
    setInboxContainerHeight()
  }
})
